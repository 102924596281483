.wp-1 {
  width: 1%;
}
.wp-2 {
  width: 2%;
}
.wp-3 {
  width: 3%;
}
.wp-4 {
  width: 4%;
}
.wp-5 {
  width: 5%;
}
.wp-6 {
  width: 6%;
}
.wp-7 {
  width: 7%;
}
.wp-8 {
  width: 8%;
}
.wp-9 {
  width: 9%;
}
.wp-10 {
  width: 10%;
}
.wp-11 {
  width: 11%;
}
.wp-12 {
  width: 12%;
}
.wp-13 {
  width: 13%;
}
.wp-14 {
  width: 14%;
}
.wp-15 {
  width: 15%;
}
.wp-16 {
  width: 16%;
}
.wp-17 {
  width: 17%;
}
.wp-18 {
  width: 18%;
}
.wp-19 {
  width: 19%;
}
.wp-20 {
  width: 20%;
}
.wp-21 {
  width: 21%;
}
.wp-22 {
  width: 22%;
}
.wp-23 {
  width: 23%;
}
.wp-24 {
  width: 24%;
}
.wp-25 {
  width: 25%;
}
.wp-26 {
  width: 26%;
}
.wp-27 {
  width: 27%;
}
.wp-28 {
  width: 28%;
}
.wp-29 {
  width: 29%;
}
.wp-30 {
  width: 30%;
}
.wp-31 {
  width: 31%;
}
.wp-32 {
  width: 32%;
}
.wp-33 {
  width: 33%;
}
.wp-34 {
  width: 34%;
}
.wp-35 {
  width: 35%;
}
.wp-36 {
  width: 36%;
}
.wp-37 {
  width: 37%;
}
.wp-38 {
  width: 38%;
}
.wp-39 {
  width: 39%;
}
.wp-40 {
  width: 40%;
}
.wp-41 {
  width: 41%;
}
.wp-42 {
  width: 42%;
}
.wp-43 {
  width: 43%;
}
.wp-44 {
  width: 44%;
}
.wp-45 {
  width: 45%;
}
.wp-46 {
  width: 46%;
}
.wp-47 {
  width: 47%;
}
.wp-48 {
  width: 48%;
}
.wp-49 {
  width: 49%;
}
.wp-50 {
  width: 50%;
}
.wp-51 {
  width: 51%;
}
.wp-52 {
  width: 52%;
}
.wp-53 {
  width: 53%;
}
.wp-54 {
  width: 54%;
}
.wp-55 {
  width: 55%;
}
.wp-56 {
  width: 56%;
}
.wp-57 {
  width: 57%;
}
.wp-58 {
  width: 58%;
}
.wp-59 {
  width: 59%;
}
.wp-60 {
  width: 60%;
}
.wp-61 {
  width: 61%;
}
.wp-62 {
  width: 62%;
}
.wp-63 {
  width: 63%;
}
.wp-64 {
  width: 64%;
}
.wp-65 {
  width: 65%;
}
.wp-66 {
  width: 66%;
}
.wp-67 {
  width: 67%;
}
.wp-68 {
  width: 68%;
}
.wp-69 {
  width: 69%;
}
.wp-70 {
  width: 70%;
}
.wp-71 {
  width: 71%;
}
.wp-72 {
  width: 72%;
}
.wp-73 {
  width: 73%;
}
.wp-74 {
  width: 74%;
}
.wp-75 {
  width: 75%;
}
.wp-76 {
  width: 76%;
}
.wp-77 {
  width: 77%;
}
.wp-78 {
  width: 78%;
}
.wp-79 {
  width: 79%;
}
.wp-80 {
  width: 80%;
}
.wp-81 {
  width: 81%;
}
.wp-82 {
  width: 82%;
}
.wp-83 {
  width: 83%;
}
.wp-84 {
  width: 84%;
}
.wp-85 {
  width: 85%;
}
.wp-86 {
  width: 86%;
}
.wp-87 {
  width: 87%;
}
.wp-88 {
  width: 88%;
}
.wp-89 {
  width: 89%;
}
.wp-90 {
  width: 90%;
}
.wp-91 {
  width: 91%;
}
.wp-92 {
  width: 92%;
}
.wp-93 {
  width: 93%;
}
.wp-94 {
  width: 94%;
}
.wp-95 {
  width: 95%;
}
.wp-96 {
  width: 96%;
}
.wp-97 {
  width: 97%;
}
.wp-98 {
  width: 98%;
}
.wp-99 {
  width: 99%;
}
.wp-100 {
  width: 100%;
}
.hp-1 {
  height: 1%;
}
.hp-2 {
  height: 2%;
}
.hp-3 {
  height: 3%;
}
.hp-4 {
  height: 4%;
}
.hp-5 {
  height: 5%;
}
.hp-6 {
  height: 6%;
}
.hp-7 {
  height: 7%;
}
.hp-8 {
  height: 8%;
}
.hp-9 {
  height: 9%;
}
.hp-10 {
  height: 10%;
}
.hp-11 {
  height: 11%;
}
.hp-12 {
  height: 12%;
}
.hp-13 {
  height: 13%;
}
.hp-14 {
  height: 14%;
}
.hp-15 {
  height: 15%;
}
.hp-16 {
  height: 16%;
}
.hp-17 {
  height: 17%;
}
.hp-18 {
  height: 18%;
}
.hp-19 {
  height: 19%;
}
.hp-20 {
  height: 20%;
}
.hp-21 {
  height: 21%;
}
.hp-22 {
  height: 22%;
}
.hp-23 {
  height: 23%;
}
.hp-24 {
  height: 24%;
}
.hp-25 {
  height: 25%;
}
.hp-26 {
  height: 26%;
}
.hp-27 {
  height: 27%;
}
.hp-28 {
  height: 28%;
}
.hp-29 {
  height: 29%;
}
.hp-30 {
  height: 30%;
}
.hp-31 {
  height: 31%;
}
.hp-32 {
  height: 32%;
}
.hp-33 {
  height: 33%;
}
.hp-34 {
  height: 34%;
}
.hp-35 {
  height: 35%;
}
.hp-36 {
  height: 36%;
}
.hp-37 {
  height: 37%;
}
.hp-38 {
  height: 38%;
}
.hp-39 {
  height: 39%;
}
.hp-40 {
  height: 40%;
}
.hp-41 {
  height: 41%;
}
.hp-42 {
  height: 42%;
}
.hp-43 {
  height: 43%;
}
.hp-44 {
  height: 44%;
}
.hp-45 {
  height: 45%;
}
.hp-46 {
  height: 46%;
}
.hp-47 {
  height: 47%;
}
.hp-48 {
  height: 48%;
}
.hp-49 {
  height: 49%;
}
.hp-50 {
  height: 50%;
}
.hp-51 {
  height: 51%;
}
.hp-52 {
  height: 52%;
}
.hp-53 {
  height: 53%;
}
.hp-54 {
  height: 54%;
}
.hp-55 {
  height: 55%;
}
.hp-56 {
  height: 56%;
}
.hp-57 {
  height: 57%;
}
.hp-58 {
  height: 58%;
}
.hp-59 {
  height: 59%;
}
.hp-60 {
  height: 60%;
}
.hp-61 {
  height: 61%;
}
.hp-62 {
  height: 62%;
}
.hp-63 {
  height: 63%;
}
.hp-64 {
  height: 64%;
}
.hp-65 {
  height: 65%;
}
.hp-66 {
  height: 66%;
}
.hp-67 {
  height: 67%;
}
.hp-68 {
  height: 68%;
}
.hp-69 {
  height: 69%;
}
.hp-70 {
  height: 70%;
}
.hp-71 {
  height: 71%;
}
.hp-72 {
  height: 72%;
}
.hp-73 {
  height: 73%;
}
.hp-74 {
  height: 74%;
}
.hp-75 {
  height: 75%;
}
.hp-76 {
  height: 76%;
}
.hp-77 {
  height: 77%;
}
.hp-78 {
  height: 78%;
}
.hp-79 {
  height: 79%;
}
.hp-80 {
  height: 80%;
}
.hp-81 {
  height: 81%;
}
.hp-82 {
  height: 82%;
}
.hp-83 {
  height: 83%;
}
.hp-84 {
  height: 84%;
}
.hp-85 {
  height: 85%;
}
.hp-86 {
  height: 86%;
}
.hp-87 {
  height: 87%;
}
.hp-88 {
  height: 88%;
}
.hp-89 {
  height: 89%;
}
.hp-90 {
  height: 90%;
}
.hp-91 {
  height: 91%;
}
.hp-92 {
  height: 92%;
}
.hp-93 {
  height: 93%;
}
.hp-94 {
  height: 94%;
}
.hp-95 {
  height: 95%;
}
.hp-96 {
  height: 96%;
}
.hp-97 {
  height: 97%;
}
.hp-98 {
  height: 98%;
}
.hp-99 {
  height: 99%;
}
.hp-100 {
  height: 100%;
}

.btn {
  border-radius: 0;
}

.text-right {
  text-align: right !important;
}
