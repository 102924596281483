@import "utils";

$color-accent: #2470dc;

$pk-color: #c051c8;
$uk-color: #e57b8b;
$uken-color: #fab5c0;
$ukde-color: #fab5c0;
$ukfr-color: #fab5c0;
$dk-color: #a1f6d9;
$litpl-color: #c7fa43;
$liten-color: #565d8f;
$litit-color: #5a6952;
$litde-color: #31a769;
$lites-color: #4bd1f1;
$litfr-color: #f16cb1;
$litpt-color: #da7014;

/*
 * Sidebar
 */
#panel {
  * {
    border-radius: 0;
  }

  .td-shop {
    font-weight: 800;
    &.td-shop-pk {
      background-color: $pk-color;
      color: #333;
    }
    &.td-shop-uk {
      background-color: $uk-color;
      color: #333;
    }
    &.td-shop-uk_en {
      background-color: $uken-color;
      color: #333;
    }
    &.td-shop-uk_de {
      background-color: $uken-color;
      color: #333;
    }
    &.td-shop-uk_fr {
      background-color: $uken-color;
      color: #333;
    }
    &.td-shop-dk {
      background-color: $dk-color;
      color: #333;
    }
    &.td-shop-lit_pl {
      background-color: $litpl-color;
      color: #333;
    }
    &.td-shop-lit_fr {
      background-color: $litfr-color;
      color: #333;
    }
    &.td-shop-lit_de {
      background-color: $litde-color;
      color: #333;
    }
    &.td-shop-lit_en {
      background-color: $liten-color;
      color: #333;
    }
    &.td-shop-lit_it {
      background-color: $litit-color;
      color: #333;
    }
    &.td-shop-lit_es {
      background-color: $lites-color;
      color: #333;
    }
    &.td-shop-lit_pt {
      background-color: $litpt-color;
      color: #333;
    }
  }

  .btn-shop {
    &.btn-shop-pk {
      color: #444 !important;
      border-color: $pk-color !important;
      &:hover {
        background-color: $pk-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-uk {
      color: #444 !important;
      border-color: $uk-color !important;
      &:hover {
        background-color: $uk-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-uk_en {
      color: #444 !important;
      border-color: $uken-color !important;
      &:hover {
        background-color: $uken-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-uk_de {
      color: #444 !important;
      border-color: $ukde-color !important;
      &:hover {
        background-color: $ukde-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-uk_fr {
      color: #444 !important;
      border-color: $ukfr-color !important;
      &:hover {
        background-color: $ukfr-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-dk {
      color: #444 !important;
      border-color: $dk-color !important;
      &:hover {
        background-color: $dk-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_pl {
      color: #444 !important;
      border-color: $litpl-color !important;
      &:hover {
        background-color: $litpl-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_fr {
      color: #444 !important;
      border-color: $litfr-color !important;
      &:hover {
        background-color: $litfr-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_de {
      color: #444 !important;
      border-color: $litde-color !important;
      &:hover {
        background-color: $litde-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_en {
      color: #444 !important;
      border-color: $liten-color !important;
      &:hover {
        background-color: $liten-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_it {
      color: #444 !important;
      border-color: $litit-color !important;
      &:hover {
        background-color: $litit-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_es {
      color: #444 !important;
      border-color: $lites-color !important;
      &:hover {
        background-color: $lites-color !important;
        color: #000 !important;
      }
    }
    &.btn-shop-lit_pt {
      color: #444 !important;
      border-color: $litpt-color !important;
      &:hover {
        background-color: $litpt-color !important;
        color: #000 !important;
      }
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

    .nav-link {
      font-weight: 500;
      color: #333;

      &::before {
        content: "";
        background-color: $color-accent;
        height: 14px;
        width: 0;
        position: relative;
        display: inline-block;
        margin-right: 0;
      }

      &.active {
        color: $color-accent;
        &::before {
          width: 10px;
          margin-right: 10px;
        }
      }

      &:hover {
        &::before {
          width: 10px;
          margin-right: 10px;
        }
      }

      &:hover .feather,
      &.active .feather {
        color: inherit;
      }

      .feather {
        margin-right: 4px;
        color: #727272;
        font-size: 14px;
        height: 14px;
        width: 14px;
        margin-top: -4px;
      }
    }

    .sidebar-sticky {
      position: relative;
      top: 0;
      height: calc(100vh - 48px);
      padding-top: 0.5rem;
      overflow-x: hidden;
      overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }

    .sidebar-heading {
      font-size: 0.75rem;
      text-transform: uppercase;
    }
  }

  @media (max-width: 767.98px) {
    .sidebar {
      top: 5rem;
    }
  }
}
/*
 * Navbar
 */

#panel {
  .navbar-brand {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  }
  .navbar {
    .navbar-toggler {
      top: 0.25rem;
      right: 1rem;
    }
    .form-control {
      padding: 0.75rem 1rem;
      border-width: 0;
      border-radius: 0;
    }
  }
  .form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
  }

  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
  }

  .logout-btn {
    cursor: pointer;

    &:hover {
      color: #fff;
      .feather {
        color: #fff;
      }
    }

    .feather {
      height: 14px;
      width: 14px;
      margin-top: -4px;
    }
  }
}

.bg-success-sent {
  background-color: #8a2be2 !important;
}

.num-stats-table-font-small {
  & > * {
    font-size: 75%;
  }
}
